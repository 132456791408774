import recall from "../../images/features/recall.svg";
import nodeLink from "../../images/features/nodeLink.svg";
import liveCollaboration from "../../images/features/icons_web-03.svg";
import flashCards from "../../images/features/flashcards.svg";
import criticalThinking from "../../images/features/icons_web-05.svg";
import sortAndStoreIdeas from "../../images/features/icons_web-06.svg";
import userNotes from "../../images/features/userNotes.svg";
import tags from "../../images/features/tags.svg";

export const FEATURE_ITEMS_00 = [
  {
    header: "Live Collaboration",
    content: "Connect with others to work on the same Ako Map in real time",
    image: liveCollaboration,
    videoURL: "https://player.vimeo.com/video/598628345",
  },
  {
    header: "Voice Recall",
    content: "Use a microphone to try remembering all the topics in your map. Get instant feedback on what you forgot",
    image: recall,
    videoURL: "https://player.vimeo.com/video/598628214",
  },
  {
    header: "One-click Flashcards",
    content:
      "Turn any map topic into a flashcard with one click. Then view your flashcards at optimal times for long-term learning",
    image: flashCards,
    videoURL: "https://player.vimeo.com/video/596828401",
  },
  {
    header: "Layers",
    content: "Add extra information in layers 'under' your main map, so you never run out of space",
    image: sortAndStoreIdeas,
    videoURL: "https://player.vimeo.com/video/596828217",
  },
  {
    header: "Node Links",
    content: "Cross-reference your ideas with ease by adding links that help connect the content from other topics",
    image: nodeLink,
    videoURL: "https://player.vimeo.com/video/596828292",
  },
  {
    header: "Thinking Tools",
    content: "Use 'truth meters' to evaluate logical arguments, communicate perspectives, and make better decisions",
    image: criticalThinking,
    videoURL: "https://player.vimeo.com/video/598628379",
  },
  {
    header: "Tags",
    content: "Tag ideas in your map by topic, then set filters to show only the topics you want",
    image: tags,
    videoURL: "https://player.vimeo.com/video/605199168?h=ba1a137f56",
  },
  {
    header: "User Notes",
    content: "Take your own private notes as you watch courses for easy revision later on",
    image: userNotes,
    videoURL: "https://player.vimeo.com/video/604517139",
  },
];
